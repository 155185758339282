<template>
  <div class="app-container">
    <div v-show="!edit.show">
      <div class="head-container">
        <quick-select url="api/brand" v-model="query.brandId" filterable placeholder="品牌" class="filter-item" @change="toQuery" clearable style="width: 120px;" />
        <quick-cascader class="filter-item" v-model="query.seriesIds" url="api/series/tree" :params="{enabled: true,brandId:query.brandId}" root-key @change="toQuery" filterable clearable placeholder="系列" expand-trigger="hover" style="width: 200px;" multiple checkStrictly collapseTags />
        <quick-cascader class="filter-item" v-model="query.categoryId" url="api/category/tree" @change="toQuery" filterable clearable placeholder="分类" expand-trigger="hover" style="width: 200px;" />
        <el-select v-model="query.onShelf" placeholder="上架状态" style="width:100px" class="filter-item" clearable @change="toQuery">
          <el-option :key="1" label="上架" :value="true"></el-option>
          <el-option :key="2" label="下架" :value="false"></el-option>
        </el-select>
        <el-input v-model="query.keywords" clearable placeholder="商品名称/编码" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
        <el-input v-model="query.specs" clearable placeholder="规格" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
        <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="toQuery" style="width: 280px;"/>
        <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
        <el-button v-if="!$erp.enable()" v-permission="['GOODS_ALL', 'GOODS_ADD']" class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="doAdd">新增</el-button>
        <!-- <el-button v-permission="['GOODS_ALL', 'GOODS_IMPORT']" class="filter-item" size="mini" type="primary" icon="el-icon-upload2" @click="doImport" v-if="$erp.enable() && $entType.get()!=='kingkoil'">导入</el-button> -->
        <el-button v-permission="['GOODS_ALL', 'GOODS_EXPORT']" class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
        <el-button v-permission="['GOODS_ALL', 'GOODS_RETAIL_IMPORT']" type="primary" size="mini" @click="uploadRetail" class="filter-item" icon="el-icon-upload2">统一零售价导入</el-button>
        <div style="display: inline-block;margin: 0px 2px;" v-permission="['GOODS_ALL','GOODS_ONSHELF']" v-if="$erp.enable()">
          <el-dropdown szie="mini" type="primary" @command="handleShelfCmd" class="filter-item">
            <el-button type="primary" :disabled="!selection || !selection.length">
              批量上下架
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="onShelf">上架</el-dropdown-item>
              <el-dropdown-item command="downShelf">下架</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
      </div>
      <el-table v-loading="loading" :data="data" row-key="_id" :default-sort="{prop: 'createTime', order: 'descending'}" @sort-change="handleSort" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="35" :selectable="checkSelectable" />
        <el-table-column prop="name" label="名称" min-width="130px" sortable="custom" show-overflow-tooltip fixed/>
        <el-table-column prop="specs" label="规格" min-width="300px"  :formatter="$goodsSpecConvert" fixed/>           
        <el-table-column prop="code" label="商品编码" width="155px" sortable="custom" v-if="!$erp.enable()"/>     
        <el-table-column prop="erpCode" label="ERP编码" width="120px" sortable="custom" v-else/>        
        <el-table-column prop="brandName" label="品牌" width="100px" sortable="custom" show-overflow-tooltip/>      
        <el-table-column prop="seriesName" label="系列" width="130px" sortable="custom" show-overflow-tooltip/>
        <el-table-column prop="categoryName" label="类目" width="100px" sortable="custom" show-overflow-tooltip/>
        <el-table-column prop="retailPrice" label="统一零售价" width="120px" sortable="custom" :formatter="$price" />
        <el-table-column label="参与返利" width="70" align="center">
          <template slot-scope="scope">
            <i class="fa fa-check" v-if="scope.row.joinRebate"></i>
          </template>
        </el-table-column>
        <el-table-column label="上下架" width="60">
          <template slot-scope="scope">
            <i class="fa fa-check" v-if="scope.row.onShelf"></i>
          </template>
        </el-table-column>
        <el-table-column prop="createAt" label="创建日期" width="100px" :formatter="v=>{return v.createAt?new Date(v.createAt).format('yyyy/MM/dd'):''}" sortable="custom" />
        <el-table-column width="150" fixed="right" v-if="!$erp.enable()">
          <div class="row-commands" slot-scope="scope" v-if="scope.row._type !== 'sku'">
            <!-- <el-button size="mini" type="text" @click="doEdit(scope.row)">{{user.entId===scope.row.entId ? "编辑" : "查看"}}</el-button> -->
            <!-- <el-button size="mini" type="text" @click="doEdit(scope.row)" >查看</el-button> -->
            <template v-if="user.entId===scope.row.entId && !$erp.enable()">
              <el-button size="mini" type="text" @click="doEdit(scope.row)" v-if="checkPermission(['GOODS_ALL','GOODS_EDIT'])">编辑</el-button>
              <el-button size="mini" type="text" @click="doEdit(scope.row)" v-else>查看</el-button>
            </template>
            <el-popover :ref="scope.row.id" placement="top" width="180" v-if="user.entId===scope.row.entId && !$erp.enable()" v-permission="['GOODS_ALL', 'GOODS_DEL']">
              <p>确定删除本条数据吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
                <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
              </div>
              <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
            </el-popover>
          </div>
        </el-table-column>
      </el-table>
      <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    </div>

    <e-form ref="form" />
    <importer ref="importer" />
    <retailForm ref="retailForm" />
  </div>
</template>
<script>
import checkPermission from "@/utils/permission";
import { parseTime } from "@/utils/index";
import { mapGetters } from "vuex";
import initData from "@/mixins/initData";
import { delSku } from "@/api/goods";
import eForm from "./form";
import importer from "../manage/import";
import request from "@/utils/request";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
// import config from "@/config";
import retailForm from "./retail-import";

export default {
  components: { importer, eForm, retailForm },
  mixins: [initData],
  data() {
    return {
      downloadLoading: false,
      delLoading: false,
      loading: false,
      edit: {
        show: false,
        current: null,
      },
      selection: [],
      //时间查询
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      query: {
        time: null,
        value: null,
        enabled: null,
        date: null,
        dateRange: null,
        brandId: null,
        seriesId: null,
        categoryId: null,
        keywords: null,
      },
      sort: "createAt,desc",
      enabledTypeOptions: [
        { key: "0", display_name: "下架" },
        { key: "1", display_name: "上架" },
        { key: "2", display_name: "待审批" },
      ],
      categories: [],
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  created() {
    this.init();
  },
  methods: {
    parseTime,
    checkPermission,
    checkSelectable(row) {
      return this.user.entId === row.entId;
    },
    handleEditCancel() {
      this.edit.show = false;
      this.toQuery();
    },
    handleSelectionChange(sels) {
      this.selection = sels;
    },
    handleShelfCmd(ac) {
      let onShelf = true;
      if (ac === "downShelf") {
        onShelf = false;
      }
      this.$confirm(
        "确定要对所选商品进行【" + (onShelf ? "上架" : "下架") + "】吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        let goodsIdList = this.selection.map((f) => f.id);
        request({
          url: "api/goods/sku/onShelf?onShelf=" + onShelf,
          method: "put",
          data: goodsIdList,
        })
          .then((_) => {
            this.init();
            this.$notify({
              title: "操作成功",
              type: "success",
              duration: 2500,
            });
          })
          .finally((_) => {
            // this.loading = false;
          });
      });
    },
    beforeInit() {
      this.url = "api/goods/sku";
      let query = JSON.parse(JSON.stringify(this.query));
      if (query.dateRange && query.dateRange.length === 2) {
        query.createAtBeg = query.dateRange[0];
        query.createAtEnd = query.dateRange[1];
      }
      delete query.dateRange;
      this.params = Object.assign({ sort: this.sort }, query);
      return true;
    },
    uploadRetail() {
      this.$refs.retailForm && this.$refs.retailForm.resetForm();
    },
    handleSort(sort) {
      if (sort.order == null) this.sort = "";
      else
        this.sort =
          sort.prop + "," + (sort.order === "ascending" ? "asc" : "desc");
      this.toQuery();
    },
    doAdd() {
      this.$refs.form && this.$refs.form.resetForm();
    },
    doEdit(data) {
      this.$refs.form &&
        this.$refs.form.resetForm(data, this.user.entId === data.entId);
    },
    subDelete(id) {
      this.delLoading = true;
      delSku(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
        });
    },
    doImport() {
      this.$refs.importer && this.$refs.importer.show();
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/goods/sku/download", this.params)
        .then((result) => {
          downloadFile(result, "商品信息", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>